import React from 'react'
import PropTypes from 'prop-types'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import CustomLink from './ui/CustomLink'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';
import { truncate, getOptimizedImageUrl } from '../../utils/helpers'
import Text from './ui/Text'
import { tagRouteBaseUrl } from '../../constants/strings'
import { navigate } from 'gatsby'
import Heading from './ui/Heading'
import Tag from './ui/Tag'

const PostCardContainer = styled.div`
    color: inherit;
    text-decoration: none;
    padding: 0 0 6px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    min-height: ${props => props.minHeight ? props.minHeight : '365px'};
    max-width: 480px;
    background-color: white;
    display: ${p => p.miniCard && 'flex'};
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }

    @media ${deviceLessThan.tablet} {
        min-height: ${props => props.sMinHeight ? props.sMinHeight : '365px'};
        max-width: unset;
        }


    & > div:first-child {
        width: 100%;
        @media ${deviceLessThan.tablet} {
            margin: ${p => p.miniCard && '0 15px 0 0'};
            width: ${p => p.innerDivWidth ? '80%' : '100%'};
        }
    }
`;

const PostCardImage = styled.div`
    margin: 0 0 10px 0;
    width: auto;
    height: 200px;
    background: var(--color-bg) no-repeat center center;
    background-size: cover;
    background-image: ${props => props.backgroundImage && `url(${props.backgroundImage})`};
    display: ${props => props.display};

    @media ${deviceLessThan.tablet} {
        margin: ${p => p.miniCard && '10px 10px 10px 0px'};
        width: ${p => p.miniCard && '125px'};
        align-self: ${p => p.miniCard && 'baseline'};
        background: ${p => p.miniCard && 'white no-repeat'};
        background-size: ${p => p.miniCard && 'contain'};
        background-image: ${props => props.backgroundImage && `url(${props.backgroundImage})`};
        display: ${props => props.sDisplay};
        height: ${p => p.miniCard && '100%'};
    }
`;

const PostCardFooterRight = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    color: #707070;
    font-size: 14px;
    @media ${deviceLessThan.tablet} {
        display: ${p => p.miniCard && 'none'};
    }
`;

const PostCardExcerpt = styled.section`
    margin: 10px;
    font-size: 14px;
    line-height: 1.58;
    opacity: 0.8;
    color: #414141;
    display: ${p => p.display};
    @media ${deviceLessThan.tablet} {
        display: ${p => p.sDisplay};
    }
`;

const PostCard = ({ post, hideFooter, sMinContainerHeight, miniCard, isOpinion }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    const onButtonClick = () => {
        navigate(url);
    }

    return (
        <PostCardContainer
            miniCard={miniCard}
            sMinHeight={sMinContainerHeight}
            innerDivWidth={miniCard && getOptimizedImageUrl(post.feature_image)}
            onClick={onButtonClick}>
            <div>
                <header className="post-card-header">
                    {post.feature_image ?
                        <PostCardImage
                            sDisplay={miniCard && 'none'}
                            miniCard={miniCard}
                            backgroundImage={getOptimizedImageUrl(post.feature_image)}></PostCardImage> :
                        <PostCardImage
                            miniCard={miniCard}
                            sDisplay={miniCard && 'none'}
                        ></PostCardImage>
                    }{
                        isOpinion &&
                        <div style={{ display: 'flex' }}>
                            <Tag
                                size='xs'
                                color='TAG'
                                weight='bold'
                                backgroundColor='#fef4f4'
                                padding='6px'
                                margin='0 10px 0 10px'
                                width="max-content"
                            >{post.primary_tag.name.toUpperCase()}</Tag>
                            <Text
                                alignSelf='center'
                                size='xxs'
                                color='CHQBOOK_BLUE_DARK'
                                weight='medium'
                            >{post.published_at_pretty}</Text>
                        </div>
                    }
                    <Heading
                        size='xxxxs'
                        margin='10px'
                        sMargin='10px'
                        color={isOpinion ? 'CHQBOOK_BLUE_DARK' : 'DARK_GREY'}
                        lineHeight='1.56'
                        weight='normal'
                        spacing='-0.19px'
                    >{post.title}
                    </Heading>
                </header>
                <PostCardExcerpt sDisplay='none'>
                    {post.excerpt}
                    <Text display='inline-block' size='sm' color='MEDIUM_GREY' weight='large'> ..read more</Text>
                </PostCardExcerpt>
                <PostCardExcerpt display='none' sDisplay='block'>{
                    miniCard ?
                        <>
                            {truncate(post.excerpt, 10)}
                            <Text display='inline-block' size='sm' color='MEDIUM_GREY' weight='large'>...read more</Text>
                        </>
                        : <>
                            {post.excerpt}
                            <Text display='inline-block' size='sm' color='MEDIUM_GREY' weight='large'> ..read more</Text>
                        </>
                }</PostCardExcerpt>
                {
                    !hideFooter &&
                    <footer className="post-card-footer">
                        <div className="post-card-footer-left">
                            {post.tags && <div className="post-card-tags">
                                <Tags permalink={`${tagRouteBaseUrl}/:slug`} post={post} visibility="public" autolink={false} />
                            </div>}
                        </div>
                        <PostCardFooterRight miniCard={miniCard}>
                            <div>{readingTime}</div>
                        </PostCardFooterRight>
                    </footer>
                }
            </div>
            {post.feature_image &&
                <PostCardImage
                    miniCard={miniCard}
                    display='none'
                    sDisplay={!miniCard ? 'none' : 'block'}
                    backgroundImage={getOptimizedImageUrl(post.feature_image)}></PostCardImage>
            }
        </PostCardContainer>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
