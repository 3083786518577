import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomLink from './ui/CustomLink';

const Pagination = ({ pageContext, indexRoute }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

    return (
        <nav className="pagination" role="navigation">
            <div>
                {
                    numberOfPages > 1 &&
                    <CustomLink
                        padding='8px 8px 8px'
                        borderRadius='2px'
                        border='solid 1px #10457f'
                        color='CHQBOOK_BLUE'
                        to={previousPagePath}
                        rel="prev"
                    >
                        <ArrowBackIosIcon />
                    </CustomLink>
                }
            </div>
            {numberOfPages > 1 && <div className="pagination-location">
                {
                    Array.from(Array(numberOfPages), (e, i) => {
                        let pageNum = i + 1;

                        if (humanPageNumber < 4) {
                            if (pageNum < 5 || pageNum == numberOfPages) {
                                return (
                                    <CustomLink
                                        activeClassName='is-active'
                                        color='LIGHTER_GREY'
                                        margin='0 20px'
                                        sMargin='0 15px'
                                        key={i}
                                        activeColor='CHQBOOK_BLUE'
                                        activeWeight='bold'
                                        to={pageNum == 1 ? indexRoute : `${indexRoute}/page/${pageNum}`}
                                    >
                                        {pageNum}
                                    </CustomLink>
                                )
                            }
                            else if (pageNum == 5) {
                                return <span key={i}>...</span>
                            }
                        }
                        else if (humanPageNumber >= 4 && humanPageNumber <= numberOfPages - 3) {
                            if (pageNum == 1 ||
                                pageNum == numberOfPages ||
                                pageNum == humanPageNumber - 1 ||
                                pageNum == humanPageNumber + 1 ||
                                pageNum == humanPageNumber
                            ) {
                                return (
                                    <CustomLink color='LIGHTER_GREY'
                                        activeClassName='is-active'
                                        color='LIGHTER_GREY'
                                        margin='0 20px'
                                        sMargin='0 15px'
                                        key={i}
                                        activeColor='CHQBOOK_BLUE'
                                        activeWeight='bold'
                                        to={pageNum == 1 ? indexRoute : `${indexRoute}/page/${pageNum}`}
                                    >
                                        {pageNum}
                                    </CustomLink>
                                )
                            }
                            else if ((pageNum == 2 || pageNum == numberOfPages - 1)) {
                                return <span key={i}>...</span>
                            }
                        }
                        else if (humanPageNumber > numberOfPages - 3) {
                            if (pageNum >= numberOfPages - 3 || pageNum == 1) {
                                return (
                                    <CustomLink color='LIGHTER_GREY'
                                        activeClassName='is-active'
                                        color='LIGHTER_GREY'
                                        margin='0 20px'
                                        sMargin='0 15px'
                                        key={i}
                                        activeColor='CHQBOOK_BLUE'
                                        activeWeight='bold'
                                        to={pageNum == 1 ? indexRoute : `${indexRoute}/page/${pageNum}`}
                                    >
                                        {pageNum}
                                    </CustomLink>
                                )
                            }
                            else if (pageNum == numberOfPages - 4) {
                                return <span key={i}>...</span>
                            }
                        }
                    })
                }
            </div>}
            <div>
                {
                    numberOfPages > 1 &&
                    <CustomLink
                        padding='8px 8px 8px'
                        borderRadius='2px'
                        border='solid 1px #10457f'
                        color='CHQBOOK_BLUE'
                        to={nextPagePath}
                        rel="next">
                        <ArrowForwardIosIcon />
                    </CustomLink>
                }
            </div>
        </nav>
    )
}

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
    indexRoute: PropTypes.string.isRequired
}

export default Pagination
